import { fetcher } from './helpers/fetch';

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 * @param {Object} param
 * @param {Buffer} param.file
 * @param {string} param.filename
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const postRemoteClocksImage = ({ file, filename, bearerToken }) => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('filename', filename);

  return fetcher('api/remote-clocks-image', {
    method: 'POST',
    body: formData,
    headers: { Authorization: bearerToken },
  });
};

/**
 *
 * @param {Object} param
 * @param {string} param.filename
 * @param {boolean} [param.checkFileExist=false]
 * @param {string=} param.bearerToken
 * @return {Promise}
 */
export const getRemoteClocksImage = ({
  filename,
  checkFileExist = false,
  bearerToken,
}) => {
  let query = `filename=${filename}`;
  if (checkFileExist) {
    query += '&checkFileExist=true';
  }
  return fetcher(`api/remote-clocks-image?${query}`, {
    headers: { Authorization: bearerToken },
  });
};
